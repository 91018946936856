<template>
  <main>
    <section class="container-fluid">
      <Navbar black active="services" @localLoaded="setlocalLoaded" />
    </section>
    <div class="services container mt-5" v-if="localLoaded">
      <div class="row">
        <div class="col">
          <h2>
            {{ $t('serviceInfo.title') }}
          </h2>
        </div>
      </div>
      <div class="row mt-4 gx-5">
        <div class="col-sm-7">
          <h4 class="services__title mb-4">
            {{ $t('serviceInfo.subtitle') }}
          </h4>
          <p class="services__paragraph">
            {{ $t('serviceInfo.listTitle') }}
          </p>
          <ul class="services__list">
            <li
              class="services__list-item mt-4"
              v-for="(service, index) in $t('serviceInfo.services')"
              :key="`service-${index}`"
            >
              {{ service.value }}
            </li>
          </ul>
        </div>
        <div class="col-sm-5">
          <img :src="getUrl + $t('serviceInfo.image.path')" class="img-fluid" alt="..." />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col"></div>
      </div>
      <div class="row mt-5"></div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import enviroment from '@/mixins/enviroment';

export default {
  mixins: [enviroment],
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'Services',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      localLoaded: false
    };
  },
  methods: {
    setlocalLoaded() {
      this.localLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss">
.services {
  &__paragraph {
    font-size: 1.25rem;
  }

  &__title {
    font-family: 'Fira Sans', sans-serif;
  }

  &__list {
    &-item {
      font-size: 1.25rem;
    }
  }
}
</style>
